import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then( m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialModule)
  },
  { 
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  { 
    path: 'office-list',
    loadChildren: () => import('./pages/office-list/office-list.module').then( m => m.OfficeListPageModule)
  },
  // { 
  //   path: 'calendar-app',
  //   loadChildren: () => import('./pages/calendar-app/calendar-app.module').then( m => m.CalendarAppModule)
  // },
  { 
    path: 'storage-manager',
    loadChildren: () => import('./pages/storage-manager/storage-manager.module').then( m => m.StorageManagerPageModule)
  },
  { 
    path: 'filter-popover',
    loadChildren: () => import('./pages/calendar-app/filter-popover/filter-popover.module').then( m => m.FilterPopoverPageModule)
  },
  { 
    path: 'issue-reporter/:type/:regId/:regName',
    loadChildren: () => import('./pages/issue-reporter/issue-reporter.module').then( m => m.IssueReporterPageModule)
  },
  { 
    path: 'register-list',
    loadChildren: () => import('./pages/register-list/register-list.module').then( m => m.RegisterListPageModule)
  },
  { 
    path: 'offline',
    loadChildren: () => import('./pages/offline/offline.module').then( m => m.OfflinePageModule)
  },
  {
    path: 'reorder-record-list',
    loadChildren: () => import('./pages/register-list/reorder-record-list/reorder-record-list.module').then( m => m.ReorderRecordListPageModule)
  },
  // {
  //   path: 'select-modal',
  //   loadChildren: () => import('./components/select-modal/select-modal.module').then( m => m.SelectModalPageModule)
  // },
  // { path: 'record-edit', loadChildren: './pages/register-list/record-edit/record-edit.module#RecordEditPageModule' },


  // { path: 'record-list', loadChildren: './pages/register-list/record-list/record-list.module#RecordListPageModule' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
