import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { MenuController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { TranslationLoaderService } from './core/translation-loader.service';
import { HelperProvider } from './providers/helper.provider';
import { NetworkProvider } from './providers/network.provider';
import { UserData } from './providers/user-data';
import { locale as english } from './i18n/en';
import { locale as polish } from './i18n/pl';
import { locale as german } from './i18n/de';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appGroups = [];
  userDetail: any = {};
  loggedIn = false;
  showMenu = false;
  currentLang: string;
  loading: HTMLIonLoadingElement;

  constructor(
    // private events: Events,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private userData: UserData,
    private helperProvider: HelperProvider,
    private translate: TranslateService,
    // private oneSignal: OneSignal,
    private translationLoader: TranslationLoaderService,
    // public network: Network,
    public networkProvider: NetworkProvider
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.translationLoader.loadTranslations(english, polish, german);

    const tempLang = window.localStorage.getItem('selectedLanguage')

    if (!tempLang) {

      if (navigator.languages && (navigator.languages[0] === 'en' ||
        navigator.languages[0] === 'pl' ||
        navigator.languages[0].includes('pl') || navigator.languages[0].includes('en'))) {
        if (navigator.languages[0].includes('pl')) {
          window.localStorage.setItem('selectedLanguage', 'pl');
        }
        if (navigator.languages[0].includes('en')) {
          window.localStorage.setItem('selectedLanguage', 'en');
        }
      } else {
        window.localStorage.setItem('selectedLanguage', 'pl');
      }
    }

    this.translate.addLangs(['en', 'pl', 'de']);
    if (window.localStorage.getItem('selectedLanguage')) {
      this.translate.setDefaultLang(window.localStorage.getItem('selectedLanguage'));
      this.translate.use(window.localStorage.getItem('selectedLanguage'));
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

    this.currentLang = this.translate.getDefaultLang();

    this.checkLoginStatus()
      .then((res) => {
        this.helperProvider.onEvent.next('user:officeChanged');
      }).catch(err => {
        this.helperProvider.showToast(err);
        this.navigate('/login');
      });
    this.listenForLoginEvents();
  }

  async setLang(lang: string) {
    this.translate.use(lang || 'en');
    window.localStorage.setItem('selectedLanguage', lang || 'en');
    this.currentLang = lang || 'en';
    this.helperProvider.getMainMenu(lang)
      .then(formattedMainMenu => {
        this.appGroups = formattedMainMenu || this.appGroups;
      }).catch(err => {
        console.log(err);
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();

      this.networkProvider.initializeNetworkEvents();

      // Offline event
      this.helperProvider.onEvent.subscribe((event) => {
        if (event !== 'network:offline') {
          return;
        }
        this.helperProvider.showToast('messages.offline');
        this.navigate('/offline');
        this.helperProvider.showToast('messages.offline_msg');
          // alert('network:offline ==> ' + this.network.type);    
      });

      // Online event
      this.helperProvider.onEvent.subscribe((event) => {
        if (event !== 'network:online') {
          return;
        }
        this.helperProvider.showToast('messages.online');
        this.navigate('/dashboard');
        // this.helperProvider.showToast('You are Online Now - ' + this.network.type);

          // alert('network:online ==> ' + this.network.type);        
      });


      // OneSignal Code start:
      // Enable to debug issues:
      // window["plugins"].OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});
      
      // this.oneSignal
      //   .startInit(environment.oneSignalAppId, environment.androidSenderId)
      //   .inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

      //   this.oneSignal.endInit();
  }).catch(err => {
    console.log(err);
  });
  }

async checkLoginStatus() {
  const loggedIn = await this.userData.isLoggedIn();
  if (!loggedIn) {
    this.updateLoggedInStatus(true);
    return await this.userData.reAuth();
  } else {
  this.updateLoggedInStatus(false);
  }
}

updateLoggedInStatus(loggedIn: boolean) {
  console.log("loggedIn = ", loggedIn);
  this.loggedIn = loggedIn;
}

listenForLoginEvents() {
  this.helperProvider.onEvent.subscribe((event) => {
    if (event !== 'user:login') {
      return;
    }
    console.log("111");
    this.updateLoggedInStatus(true);
    this.menu.enable(true);
  });

  this.helperProvider.onEvent.subscribe((event) => {
    if (event !== 'user:signup') {
      return;
    }
    console.log("222");
    this.updateLoggedInStatus(true);
  });

  this.helperProvider.onEvent.subscribe(async (event) => {
    if (event !== 'user:logout') {
      return;
    }
    // this.updateLoggedInStatus(false);

  });

  this.helperProvider.onEvent.subscribe((event) => {
    if (event !== 'user:officeChanged') {
      return;
    }

    setTimeout(() => {
      if (this.loggedIn) {
        this.updateOfficeInfo();
        this.helperProvider.onEvent.next('office:refreshMenu');
        let officeData: any = window.localStorage.getItem('ngStorage-selectedOffice');
        if (officeData) {
          officeData = JSON.parse(officeData);
        }
        
        if (officeData && !officeData.showOnMobile) {
          return this.router.navigateByUrl("/office-list");
        }
        
        this.navigate('/dashboard');
      } else {
        this.navigate('/login');
      }
    }, 800);

  });

  this.helperProvider.onEvent.subscribe((event) => {
    if (event !== 'office:refreshMenu') {
      return;
    }
    // this.toggleLoading(true);
    this.helperProvider.getMainMenu()
      .then(formattedMainMenu => {
        // this.toggleLoading(false);
        this.appGroups = formattedMainMenu || this.appGroups;
      }).catch(err => {
        console.log(err);
        // this.toggleLoading(false);
      });
  });
}

selectTab(index: number, fallbackUrl: string) {
  const tabs = document.querySelector('ion-tabs');
  let promise: Promise<any> = null;
  if (tabs) {
    promise = tabs.componentOnReady();
    promise.then(() => {
      return tabs.select(index as any);
    });
  } else {
    promise = this.navigate(fallbackUrl);
  }
  return promise.then(() => {
    return this.menu.toggle();
  });
}

menuClicked(item) {
  window.localStorage.setItem('currentMenuItem', item);
  this.navigate(item.url);
}

navigate(url: string) {
  return this.router.navigateByUrl(url);
}

async logout() {
  this.userData.logout();
  this.navigate('/login');
  await this.menu.close();
  this.menu.enable(false);
}

openTutorial() {
  this.menu.enable(false);
  window.localStorage.setItem('ion_did_tutorial', 'false');
  this.router.navigateByUrl('/tutorial');
}

updateOfficeInfo() {
  if (window.localStorage.getItem('ngStorage-selectedOffice') && window.localStorage.getItem('ngStorage-selectedOffice') !== "undefined" && JSON.parse(window.localStorage.getItem('ngStorage-selectedOffice')) &&
  window.localStorage.getItem('ngStorage-token')
  ) {
    this.userDetail['officeName'] = JSON.parse(window.localStorage.getItem('ngStorage-selectedOffice'))['name'];
    this.userDetail['email'] = (JSON.parse(window.localStorage.getItem('ngStorage-profile'))['email']) || '';
  } else {
    this.userDetail = {};
  }
}

  async toggleLoading(show: boolean) {
    if (show) {
      this.loading = await this.helperProvider.showLoading('messages.fetching_data');
    } else {
      if (this.loading) {
        this.loading.dismiss();
      }
    }
  }

  
toggleMenu() {
  this.showMenu=!this.showMenu;
}


}
